import React from 'react';
import { Button, Box } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const UploadFloorplan = ({
  setThumbnail,
  setRooms,
  setUploadProgress,
  setIsAnalyzing,
  PIXELS_PER_METER,
}) => {
  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadProgress(0);
      setIsAnalyzing(true);

      const formData = new FormData();
      formData.append('floorplan', file);

      try {
        const response = await fetch(`${API_ENDPOINT}api/upload-floorplan`, {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();
        console.log('Response Data:', data); // Log the response data

        if (!data.rooms || !Array.isArray(data.rooms.floorplan)) {
          throw new Error('Invalid response format');
        }

        // Convert room dimensions from meters to pixels and position using x-centre and y-centre
        const convertedRooms = data.rooms.floorplan.map((room) => ({
          id: room.id,
          name: room.name,
          width: room.x * PIXELS_PER_METER, // Using x as width
          length: room.y * PIXELS_PER_METER, // Using y as length
          x: room['x-centre'] - (room.x * PIXELS_PER_METER) / 2, // Centre position for x
          y: room['y-centre'] - (room.y * PIXELS_PER_METER) / 2, // Centre position for y
        }));

        setThumbnail(URL.createObjectURL(file));
        setRooms(convertedRooms);
        setIsAnalyzing(false);
      } catch (error) {
        console.error('Error uploading floorplan:', error);
        setIsAnalyzing(false);
      }
    }
  };

  return (
    <Box my={2}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-button"
        type="file"
        onChange={handleUpload}
      />
      <label htmlFor="upload-button">
        <Button variant="contained" component="span" color="primary" style={{ marginBottom: '10px' }}>
          Upload Floorplan
        </Button>
      </label>
    </Box>
  );
};

export default UploadFloorplan;

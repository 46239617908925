import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Stage, Layer } from 'react-konva';
import Room from './Room';
import NewRoom from './NewRoom';
import { SwatchBox, SwatchColorBox, SwatchHex, SwatchName } from '../../styles';
import { PIXELS_PER_METER } from './Constants';
import axios from 'axios';

const FloorPlan = ({
  rooms,
  setRooms,
  newRoom,
  setNewRoom,
  selectedRoomId,
  setSelectedRoomId,
  selectedColor,
  setSelectedColor,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [draggingRoomId, setDraggingRoomId] = useState(null);
  const [resizing, setResizing] = useState({ isResizing: false, roomId: null, handle: null });
  const [hoveringHandle, setHoveringHandle] = useState({ roomId: null, handle: null });
  const [selectedWall, setSelectedWall] = useState({ roomId: null, wall: null });
  const [hoveredSwatch, setHoveredSwatch] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);
  const stageRef = useRef(null);

  const snapToGrid = useCallback((value) => {
    const gridSize = 0.1 * PIXELS_PER_METER; // 0.1m in pixels
    return Math.round(value / gridSize) * gridSize;
  }, []);

  const getMousePosition = useCallback((stage) => {
    const mousePos = stage.getPointerPosition();
    return {
      x: mousePos.x / stage.scaleX(),
      y: mousePos.y / stage.scaleY()
    };
  }, []);

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
    const newScale = newZoom / 100;
    stageRef.current.scale({ x: newScale, y: newScale });
    stageRef.current.batchDraw();
  };

  const handleDeleteRoom = () => {
    if (selectedRoomId) {
      setRooms(rooms.filter(room => room.id !== selectedRoomId));
      setSelectedRoomId(null);
      setSelectedWall({ roomId: null, wall: null });
    }
  };
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

useEffect(() => {
  const fetchRooms = async () => {
    try {
      const response = await axios.get(`https://paintquotepro-server.azurewebsites.net/api/rooms`);
      setRooms(response.data);
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };
  fetchRooms();
}, []);

const addRoom = async (newRoom) => {
  try {
    const response = await axios.post(`https://paintquotepro-server.azurewebsites.net/api/rooms`, newRoom);
    setRooms([...rooms, response.data]);
  } catch (error) {
    console.error('Error adding room:', error);
  }
};

  useEffect(() => {
    if (selectedColor && selectedWall.roomId && selectedWall.wall) {
      const updatedRooms = rooms.map((room) => {
        if (room.id === selectedWall.roomId) {
          return {
            ...room,
            walls: {
              ...room.walls,
              [selectedWall.wall]: {
                ...room.walls[selectedWall.wall],
                color: selectedColor,
              },
            },
          };
        }
        return room;
      });
      setRooms(updatedRooms);
    }
  }, [selectedColor, selectedWall, rooms, setRooms]);

  const handleMouseDown = (e) => {
    const target = e.target;
    if (target.name() === 'room' || target.name() === 'handle' || target.name() === 'text') {
      return;
    }
    const stage = e.target.getStage();
    const mousePos = getMousePosition(stage);
    setIsDrawing(true);
    const newId = `room-${rooms.length}`;
    const newName = `Room ${rooms.length + 1}`;
    setNewRoom({
      id: newId,
      x: snapToGrid(mousePos.x),
      y: snapToGrid(mousePos.y),
      width: 0,
      length: 0,
      name: newName,
      walls: { top: {}, bottom: {}, left: {}, right: {} }
    });
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePosition(stage);
    setMousePosition(mousePos);
    if (isDrawing) {
      const width = snapToGrid(mousePos.x - newRoom.x);
      const length = snapToGrid(mousePos.y - newRoom.y);
      setNewRoom({
        ...newRoom,
        width,
        length,
      });
    } else if (resizing.isResizing) {
      const { roomId, handle } = resizing;
      const roomIndex = rooms.findIndex((room) => room.id === roomId);
      const newRooms = rooms.slice();
      const room = newRooms[roomIndex];

      const snappedX = snapToGrid(mousePos.x);
      const snappedY = snapToGrid(mousePos.y);

      switch (handle) {
        case 'top-left':
          room.width = snapToGrid(room.width + room.x - snappedX);
          room.length = snapToGrid(room.length + room.y - snappedY);
          room.x = snappedX;
          room.y = snappedY;
          break;
        case 'top-right':
          room.width = snapToGrid(snappedX - room.x);
          room.length = snapToGrid(room.length + room.y - snappedY);
          room.y = snappedY;
          break;
        case 'bottom-left':
          room.width = snapToGrid(room.width + room.x - snappedX);
          room.x = snappedX;
          room.length = snapToGrid(snappedY - room.y);
          break;
        case 'bottom-right':
          room.width = snapToGrid(snappedX - room.x);
          room.length = snapToGrid(snappedY - room.y);
          break;
        default:
          break;
      }

      setRooms(newRooms);
    }
  };

  const handleMouseUp = () => {
    if (isDrawing && newRoom) {
      if (Math.abs(newRoom.width) >= PIXELS_PER_METER * 2 && Math.abs(newRoom.length) >= PIXELS_PER_METER * 2) {
        setRooms((prevRooms) => [...prevRooms, newRoom]);
      }
    }
    setIsDrawing(false);
    setNewRoom(null);
    setResizing({ isResizing: false, roomId: null, handle: null });
  };

  const handleDragStart = useCallback((id) => {
    setDraggingRoomId(id);
  }, []);

  const handleDragMove = useCallback((room, e) => {
    const newRooms = rooms.map((r) =>
      r.id === room.id
        ? {
            ...r,
            x: snapToGrid(e.target.x()),
            y: snapToGrid(e.target.y()),
          }
        : r
    );
    setRooms(newRooms);
  }, [rooms, setRooms, snapToGrid]);

  const handleDragEnd = useCallback((room, e) => {
    setDraggingRoomId(null);
    const newRooms = rooms.map((r) =>
      r.id === room.id
        ? {
            ...r,
            x: snapToGrid(e.target.x()),
            y: snapToGrid(e.target.y()),
          }
        : r
    );
    setRooms(newRooms);
  }, [rooms, setRooms, snapToGrid]);

  const handleRoomClick = useCallback((id) => {
    setSelectedRoomId(id);
    setSelectedWall({ roomId: null, wall: null });
  }, []);

  const handleWallClick = useCallback((roomId, wall) => {
    const room = rooms.find((r) => r.id === roomId);
    if (room) {
      if (!room.walls) {
        room.walls = { top: {}, bottom: {}, left: {}, right: {} };
      }
      setSelectedWall({ roomId, wall });
      setSelectedRoomId(roomId);
    }
  }, [rooms]);

  const handleMouseEnter = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'move';
    if (e.target.name() === 'room') {
      setHoveringHandle({ roomId: e.target.attrs.id, handle: null });
    }
  };

  const handleMouseLeave = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'default';
    setHoveringHandle({ roomId: null, handle: null });
  };

  const handleResizeMouseEnter = (e, roomId, handle) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'nwse-resize';
    setHoveringHandle({ roomId, handle });
  };

  const handleResizeMouseLeave = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'default';
    setHoveringHandle({ roomId: null, handle: null });
  };

  const handleResizeMouseDown = (roomId, handle) => {
    setResizing({ isResizing: true, roomId, handle });
  };

  const handleStageClick = (e) => {
    const target = e.target;
    if (target === stageRef.current) {
      setSelectedRoomId(null);
      setSelectedWall({ roomId: null, wall: null });
    }
  };

  // Memoize the rooms array to prevent unnecessary re-renders
  const memoizedRooms = useMemo(() => rooms, [rooms]);

  return (
    <div style={{ position: 'relative', paddingBottom: '25%' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <button onClick={() => handleZoomChange(Math.max(zoom - 10, 10))}>-</button>
        <input
          type="range"
          min="10"
          max="200"
          value={zoom}
          onChange={(e) => handleZoomChange(Number(e.target.value))}
          style={{ margin: '0 10px', width: '200px' }}
        />
        <button onClick={() => handleZoomChange(Math.min(zoom + 10, 200))}>+</button>
        <span style={{ marginLeft: '10px' }}>{zoom}%</span>
        <button 
          onClick={() => handleZoomChange(100)} 
          style={{ marginLeft: '10px' }}
        >
          Fit
        </button>
        <button 
          onClick={handleDeleteRoom} 
          style={{ marginLeft: '20px', backgroundColor: '#ff4d4d', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '4px' }}
          disabled={!selectedRoomId}
        >
          Delete Room
        </button>
      </div>
      <Stage
        ref={stageRef}
        width={window.innerWidth * 0.6}
        height={window.innerHeight}
        scaleX={zoom / 100}
        scaleY={zoom / 100}
        onMouseDown={(e) => {
          handleMouseDown(e);
          handleStageClick(e);
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          {memoizedRooms.map((room) => (
            <Room
              key={room.id}
              room={room}
              selectedRoomId={selectedRoomId}
              draggingRoomId={draggingRoomId}
              selectedWall={selectedWall}
              handleDragStart={handleDragStart}
              handleDragMove={handleDragMove}
              handleDragEnd={handleDragEnd}
              handleRoomClick={handleRoomClick}
              handleWallClick={handleWallClick}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleResizeMouseEnter={handleResizeMouseEnter}
              handleResizeMouseLeave={handleResizeMouseLeave}
              handleResizeMouseDown={handleResizeMouseDown}
              onWallHover={setHoveredSwatch}
            />
          ))}
          <NewRoom newRoom={newRoom} />
        </Layer>
      </Stage>
      {hoveredSwatch && (
        <SwatchBox style={{ position: 'absolute', left: mousePosition.x - 100, top: mousePosition.y }}>
          <SwatchColorBox bgcolor={hoveredSwatch.color}>
            <SwatchHex>{hoveredSwatch.hexValue}</SwatchHex>
          </SwatchColorBox>
          <SwatchName>{hoveredSwatch.name}</SwatchName>
        </SwatchBox>
      )}
    </div>
  );
};

export default FloorPlan;
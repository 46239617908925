import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const UploadButton = styled(Button)({
  margin: '10px 0',
  backgroundColor: '#007bff',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const SwatchBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderRadius: '3px',
  backgroundColor: '#fff',
  margin: '15px 5px',
  dropshadow: '0px 0px 20px rgba(0, 0, 0, 1)',
});

const SwatchColorBox = styled(Box)({
  width: '150px',
  height: '175px',
  borderRadius: '3px',
  border: '0px solid #333',
  marginBottom: '5px',
  position: 'relative',
});

const SwatchHex = styled(Typography)(({ contrastColor }) => ({
  position: 'absolute',
  bottom: '5px',
  left: '10px',
  color: contrastColor,
  fontSize: '14px',
}));

const SwatchName = styled(Typography)({
  marginTop: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#222',
});

const CONTRAST_THRESHOLD = 3;

const Palette = ({ colorChartPath, onSwatchClick }) => { 
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [updatedImageUrl, setUpdatedImageUrl] = useState('');
  const [swatchData, setSwatchData] = useState(null);

  const inputCanvasRef = useRef(null);
  const outputCanvasRef = useRef(null);

  useEffect(() => {
    const savedImage = localStorage.getItem('image');
    const savedUpdatedImageUrl = localStorage.getItem('updatedImageUrl');
    const savedSwatchData = localStorage.getItem('swatchData');

    if (savedImage) {
      const blob = b64toBlob(savedImage);
      setImage(blob);
    }
    if (savedUpdatedImageUrl) setUpdatedImageUrl(savedUpdatedImageUrl);
    if (savedSwatchData) setSwatchData(JSON.parse(savedSwatchData));
  }, []);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        localStorage.setItem('image', reader.result.split(',')[1]);
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  useEffect(() => {
    if (updatedImageUrl) {
      localStorage.setItem('updatedImageUrl', updatedImageUrl);
    }
  }, [updatedImageUrl]);

  useEffect(() => {
    if (swatchData) {
      localStorage.setItem('swatchData', JSON.stringify(swatchData));
    }
  }, [swatchData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Clear local storage and reset state
      localStorage.clear();
      setImage(null);
      setUpdatedImageUrl('');
      setSwatchData(null);
      setError(null);
      setImage(file);

      // Clear canvas contexts
      if (inputCanvasRef.current) {
        const inputCtx = inputCanvasRef.current.getContext('2d');
        inputCtx.clearRect(0, 0, inputCanvasRef.current.width, inputCanvasRef.current.height);
      }
      if (outputCanvasRef.current) {
        const outputCtx = outputCanvasRef.current.getContext('2d');
        outputCtx.clearRect(0, 0, outputCanvasRef.current.width, outputCanvasRef.current.height);
      }
    }
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
  const handleUpload = async () => {
    if (!image) return;

    const formData = new FormData();
    formData.append('file', image);

    setUploading(true);

    try {
      const response = await axios.post(`https://paintquotepro-server.azurewebsites.net/api/generate-colour-swatch`, formData);
      if (response.status === 200) {
        const data = response.data;


        // Load existing palettes from local storage
        const existingPalettes = JSON.parse(localStorage.getItem('savedPalettes') || '[]');

        // Determine new palette name
        const newPaletteName = `palette_${String(existingPalettes.length + 1).padStart(3, '0')}`;

        // Create new palette
        const newPalette = {
          name: newPaletteName,
          swatchData: data.swatchData
        };

        // Combine existing and new palettes
        const updatedPalettes = [...existingPalettes, newPalette];

        // Save the combined palettes to local storage
        localStorage.setItem('savedPalettes', JSON.stringify(updatedPalettes));

        setUpdatedImageUrl(data.updatedImageUrl);
        setSwatchData(data.swatchData);
      } else {
        console.error('Failed to upload the photo');
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError('Error uploading photo');
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (image) {
      const inputCtx = inputCanvasRef.current.getContext('2d');
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const canvasWidth = 1000;
        const canvasHeight = canvasWidth / aspectRatio;
        inputCanvasRef.current.width = canvasWidth;
        inputCanvasRef.current.height = canvasHeight;
        inputCtx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
        URL.revokeObjectURL(img.src);
      };
    }
  }, [image]);

  useEffect(() => {
    if (updatedImageUrl) {
      const outputCtx = outputCanvasRef.current.getContext('2d');
      const img = new Image();
      img.src = updatedImageUrl;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const canvasWidth = 500;
        const canvasHeight = canvasWidth / aspectRatio;
        outputCanvasRef.current.width = canvasWidth;
        outputCanvasRef.current.height = canvasHeight;
        outputCtx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
      };
    }
  }, [updatedImageUrl]);

  // Helper function to convert RGB to HEX
  const rgbToHex = (rgb) => {
    const rgbValues = rgb.match(/\d+/g);
    return rgbValues ? `#${((1 << 24) + (parseInt(rgbValues[0]) << 16) + (parseInt(rgbValues[1]) << 8) + parseInt(rgbValues[2])).toString(16).slice(1)}` : '#FFFFFF';
  };

  // Helper function to calculate luminance
  const luminance = (r, g, b) => {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  // Helper function to calculate contrast ratio
  const contrastRatio = (rgb1, rgb2) => {
    const lum1 = luminance(...rgb1);
    const lum2 = luminance(...rgb2);
    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
  };

  // Helper function to convert hex to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  // Determine text color based on contrast
  const getContrastColor = (hexColor) => {
    const rgbColor = hexToRgb(hexColor);
    const white = [255, 255, 255];
    const black = [0, 0, 0];
    return contrastRatio(rgbColor, white) < CONTRAST_THRESHOLD ? 'black' : 'white';
  };

  // Helper function to convert base64 to Blob
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  return (
    <Box p={2} border="0px solid #ccc" borderRadius="0px" style={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)', 
      width: '100%' 
    }}>
      <Typography variant="h6" gutterBottom>
        Create your custom palette
      </Typography>
      {/* {colorChartPath && (
        <Box mt={2}>
          <Typography>Color Chart:</Typography>
          <img src={colorChartPath} alt="Color Chart" width="50%" />
        </Box>
      )} */}
      <Box>
        <UploadButton variant="contained" component="label">
          Upload Image
          <input type="file" accept="image/*" onChange={handleFileChange} required hidden />
        </UploadButton>
        <UploadButton variant="contained" onClick={handleUpload} disabled={!image || uploading}>
          {uploading ? <CircularProgress size={24} /> : 'Generate Color Swatch'}
        </UploadButton>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <Box display="flex" justifyContent="space-around" mt={2}>
        <Box textAlign="center" style={{ width: '45%' }}>
         <canvas ref={inputCanvasRef} style={{ width: '100%', borderRadius: '8px' }} />
        </Box>
        <Box textAlign="center" style={{ width: '45%' }}>
          <canvas ref={outputCanvasRef} style={{ width: '100%', borderRadius: '8px' }} />
        </Box>
      </Box>
      {swatchData && (
        <Box mt={4}>
          <Box display="flex" flexWrap="wrap" justifyContent="space-around">
            {swatchData.map((color, index) => {
              const rgbValues = color.rgb.match(/\d+/g); 
              const rgbString = rgbValues ? `rgb(${rgbValues.join(', ')})` : 'rgb(255, 255, 255)'; 
              const hexValue = rgbToHex(color.rgb); 
              const contrastColor = getContrastColor(hexValue); // Determine contrast color
              return (
                <SwatchBox key={index} onClick={() => onSwatchClick(color.rgb)}> 
                  <SwatchColorBox bgcolor={rgbString}>
                    <SwatchHex contrastColor={contrastColor}>{hexValue}</SwatchHex>
                  </SwatchColorBox>
                  <SwatchName>{color.name}</SwatchName>
                </SwatchBox>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Palette;

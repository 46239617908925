import React from 'react';
import { Box, Typography } from '@mui/material';
import { GridView, Gradient, ViewStream, ViewQuilt } from '@mui/icons-material';
import CustomSlider from './CustomSlider'; 
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton'; 

const RoomOptions = ({
  style,
  setStyle,
  coverage,
  setCoverage,
  metersSquared,
  setMetersSquared,
  roomHeight,
  setRoomHeight,
  wallCoats,
  setWallCoats,
  roomPrep,
  setRoomPrep,
  color,
  setColor,
}) => {
  const handleStyleChange = (event, newStyle) => {
    setStyle(newStyle);
  };

  return (
    <Box p={2} border="0px solid white" borderRadius="8px"  style={{
      backgroundColor: 'rgba(255, 255, 255, 0.0)',
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)', 
    }}>
      <Typography sx={{ fontSize: '22px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>
        Floor Plan Options
      </Typography>
      <Box my={2}>
        <CustomToggleButtonGroup value={style} exclusive onChange={handleStyleChange} fullWidth>
          <CustomToggleButton value="pattern"><ViewStream /></CustomToggleButton>
          <CustomToggleButton value="grid"><GridView /></CustomToggleButton>
          <CustomToggleButton value="slant"><ViewQuilt /></CustomToggleButton>
          <CustomToggleButton value="mosaic"><Gradient /></CustomToggleButton>
          <CustomToggleButton value="gradient"><Gradient /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>Paint Coverage</Typography>
      <CustomSlider value={coverage} onChange={(e, val) => setCoverage(val)} valueLabelDisplay="auto" min={10} max={20}/>
      <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>Productivity (m²/hour)</Typography>
      <CustomSlider value={metersSquared} onChange={(e, val) => setMetersSquared(val)} valueLabelDisplay="auto" min={10} max={20} />
      <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>Room Height</Typography>
      <CustomSlider value={roomHeight} onChange={(e, val) => setRoomHeight(val)} valueLabelDisplay="auto" min={2.5} max={7.0} step={0.1}/>
      <Box my={2}>
        <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>Wall Coats</Typography>
        <CustomToggleButtonGroup value={wallCoats} exclusive onChange={(e, val) => setWallCoats(val)} fullWidth>
          <CustomToggleButton value="1">One</CustomToggleButton>
          <CustomToggleButton value="2">Two</CustomToggleButton>
          <CustomToggleButton value="3">Three</CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Box my={2}>
        <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>Room Prep</Typography>
        <CustomToggleButtonGroup value={roomPrep} exclusive onChange={(e, val) => setRoomPrep(val)} fullWidth>
          <CustomToggleButton value="low">Low</CustomToggleButton>
          <CustomToggleButton value="medium">Med</CustomToggleButton>
          <CustomToggleButton value="high">High</CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default RoomOptions;

import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './CheckoutForm.css';

const SubscriptionForm = ({ email }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [planId, setPlanId] = useState('plan_monthly'); // Default to the monthly plan ID from Stripe
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (stripeError) {
        console.error(stripeError);
        setError(stripeError.message);
        return;
      }

      const response = await axios.post(`${API_ENDPOINT}api/subscription/create-subscription`, {
        paymentMethodId: paymentMethod.id,
        email: email,
        planId: planId,
      });

      const { clientSecret } = response.data;

      const result = await stripe.confirmCardPayment(clientSecret);

      if (result.error) {
        console.error('Subscription failed:', result.error.message);
        setError(result.error.message);
        return;
      }

      if (result.paymentIntent.status === 'succeeded') {
        console.log('Subscription succeeded:', result.paymentIntent);
        setSuccess(true);
      }
    } catch (error) {
      console.error('Error during subscription:', error);
      setError('Subscription failed. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="SubscriptionForm">
      <div>
        <label>
          <input
            type="radio"
            value="price_1PfR9iCBop2DWPGzBxuOnATO" // Replace with your actual plan ID
            checked={planId === 'price_1PfR9iCBop2DWPGzBxuOnATO'}
            onChange={(e) => setPlanId(e.target.value)}
          />
          Monthly - $10
        </label>
        <label>
          <input
            type="radio"
            value="price_1PfO76CBop2DWPGzsrCaSkOO" // Replace with your actual plan ID
            checked={planId === 'price_1PfO76CBop2DWPGzsrCaSkOO'}
            onChange={(e) => setPlanId(e.target.value)}
          />
          Yearly - $100
        </label>
      </div>
      <div style={{ marginBottom: '20px', padding: '12px', border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#fff' }}>
        <CardElement />
      </div>
      <button type="submit" disabled={!stripe} className="CheckoutButton">
        Subscribe
      </button>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">Subscription successful!</div>}
    </form>
  );
};

export default SubscriptionForm;
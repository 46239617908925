import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { PictureAsPdf, Email } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Invoicing.css';

const Invoicing = ({ logo, clientFullName, clientEmail, clientPhone, clientStreetAddress, clientCity, clientState, clientPostalCode, clientCountry, numInvoices }) => {

  const handleDownloadPDF = (invoiceNumber) => {
    const invoiceElement = document.getElementById(`invoice-${invoiceNumber}`);
    html2canvas(invoiceElement, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice-${invoiceNumber}.pdf`);
    });
  };

  const handleEmailPDF = (invoiceNumber) => {
    const invoiceElement = document.getElementById(`invoice-${invoiceNumber}`);
    html2canvas(invoiceElement, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const pdfBlob = pdf.output('blob');
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        const mailtoLink = `mailto:${clientEmail}?subject=Invoice&body=Please find attached the invoice.&attachment=${base64data}`;
        window.location.href = mailtoLink;
      };
    });
  };
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  return (
    <Box p={2}>
      {Array.from({ length: numInvoices }, (_, index) => (
        <Box key={index} mb={4}>
          <Box display="flex" justifyContent="flex-start">
            <IconButton onClick={() => handleDownloadPDF(index + 1)} sx={{ fontSize: 40 }}>
              <PictureAsPdf fontSize="inherit" />
            </IconButton>
            <IconButton onClick={() => handleEmailPDF(index + 1)} sx={{ fontSize: 40 }}>
              <Email fontSize="inherit" />
            </IconButton>
          </Box>
          <Box className="invoice-container" sx={{ transform: 'scale(1)', transformOrigin: 'top left' }}>
            <Box className="invoice" id={`invoice-${index + 1}`}>
              <Box className="header" display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Box className="logo" ml={2}>
                    {logo ? (
                      <img src={`${API_ENDPOINT}${logo}`} alt="Company Logo" className="company-logo" />
                    ) : (
                      <Typography variant="h1" className="invoice-title">{logo}</Typography>
                    )}
                  </Box>
                </Box>
                <Box className="invoice-details" textAlign="right">
                  <Typography variant="h2" className="invoice-header">INVOICE</Typography>
                  <Typography>No. #{index + 1}</Typography>
                  <Typography>Date 05.01.2025</Typography>
                  <Typography>Amount Due:</Typography>
                  <Typography variant="h6" className="invoice-amount">USD: $ 1080</Typography>
                </Box>
              </Box>

              <Box className="bill-to" my={2}>
                <Typography>Bill To:</Typography>
                <Typography><strong>{clientFullName}</strong></Typography>
                <Typography>{clientStreetAddress}</Typography>
                <Typography>{clientCity}, {clientState}</Typography>
                <Typography>{clientPostalCode} {clientCountry}</Typography>
              </Box>

              <Box component="table" className="invoice-table" width="100%" borderCollapse="collapse" mb={2}>
                <Box component="thead">
                  <tr>
                    <th className="table-header">ITEM DESCRIPTION</th>
                    <th className="table-header">PRICE</th>
                    <th className="table-header">QTY</th>
                    <th className="table-header">TOTAL</th>
                  </tr>
                </Box>
                <Box component="tbody">
                  <tr>
                    <td className="table-cell">
                      Logo Design<br /><span className="item-subdescription">Restaurant branding design</span>
                    </td>
                    <td className="table-cell">$500</td>
                    <td className="table-cell">1</td>
                    <td className="table-cell">$500</td>
                  </tr>
                  <tr>
                    <td className="table-cell">
                      Web Design<br /><span className="item-subdescription">Restaurant website revamp</span>
                    </td>
                    <td className="table-cell">$500</td>
                    <td className="table-cell">1</td>
                    <td className="table-cell">$500</td>
                  </tr>
                  <tr>
                    <td className="table-cell">
                      Brochure Design<br /><span className="item-subdescription">Restaurant brochure design</span>
                    </td>
                    <td className="table-cell">$200</td>
                    <td className="table-cell">1</td>
                    <td className="table-cell">$200</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="table-cell">Subtotal</td>
                    <td className="table-cell">$1200</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="table-cell">Discount</td>
                    <td className="table-cell">10%</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="table-cell">Grand Total</td>
                    <td className="table-cell">$1080</td>
                  </tr>
                </Box>
              </Box>

              <Box className="footer" display="flex" justifyContent="space-between">
                <Box className="thank-you">
                  <Typography variant="h2" className="thank-you-text">THANK YOU!</Typography>
                </Box>
                <Box className="payment-method">
                  <Typography><strong>PAYMENT METHOD</strong></Typography>
                  <Typography>Payment: VISA</Typography>
                  <Typography>Paypal: jito@email.com</Typography>
                </Box>
                <Box className="contact">
                  <Typography><strong>CONTACT</strong></Typography>
                  <Typography>103 Main St, Portland OR</Typography>
                  <Typography>jito@email.com</Typography>
                  <Typography>1.800.555.4321</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Invoicing;

import React from 'react';
import { TextField, Box, Typography, InputAdornment } from '@mui/material';
import { Home, LocationCity, LocationOn, Mail, Person as PersonIcon, Phone, Public, Place } from '@mui/icons-material';

const PersonPanel = ({ 
  clientFullName, setClientFullName, 
  clientEmail, setClientEmail, 
  clientPhone, setClientPhone, 
  clientStreetAddress, setClientStreetAddress, 
  clientCity, setClientCity, 
  clientState, setClientState, 
  clientPostalCode, setClientPostalCode, 
  clientCountry, setClientCountry 
}) => {
  const inputStyle = {
    margin: '15px 0',
    '& .MuiInputBase-root': {
      height: '40px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.1rem',
      color: 'black',
    },
    '& .MuiInputBase-input': {
      fontSize: '1.1rem',
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
      // '&.Mui-focused .MuiInputBase-input': {
      //   backgroundColor: 'white',
      // },
    },
  };

  return (
    <Box p={2} border="0px solid #fff" borderRadius="8px" style={{
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)', // For Safari support
    }}>
      <Typography variant="h6" gutterBottom>
        Client Details
      </Typography>
      <TextField
        label="Full Name"
        value={clientFullName}
        onChange={(e) => setClientFullName(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Email Address"
        value={clientEmail}
        onChange={(e) => setClientEmail(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Mail />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Phone Number"
        value={clientPhone}
        onChange={(e) => setClientPhone(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Phone />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Street Address"
        value={clientStreetAddress}
        onChange={(e) => setClientStreetAddress(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Home />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="City"
        value={clientCity}
        onChange={(e) => setClientCity(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationCity />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="State/Province"
        value={clientState}
        onChange={(e) => setClientState(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOn />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Postal/ZIP Code"
        value={clientPostalCode}
        onChange={(e) => setClientPostalCode(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Place />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Country"
        value={clientCountry}
        onChange={(e) => setClientCountry(e.target.value)}
        fullWidth
        sx={inputStyle}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Public />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default PersonPanel;

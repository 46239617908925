import React, { useState } from 'react';
import { TextField, Box, Typography, InputAdornment, Button } from '@mui/material';
import { Business, ConfirmationNumber, Gavel, Home, LocationCity, LocationOn, Public, Place } from '@mui/icons-material';
import { Gradient, ViewQuilt, ColorLens, FormatPaint, } from '@mui/icons-material';

import axios from 'axios';


import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';

const AccountPanel = ({
  companyName, setCompanyName,
  companyNumber, setCompanyNumber,
  vatNumber, setVatNumber,
  streetAddress, setStreetAddress,
  city, setCity,
  state, setState,
  postalCode, setPostalCode,
  country, setCountry,
  logo, setLogo,
  email, setEmail,
  phoneNumber, setPhoneNumber,
  whatsApp, setWhatsApp,
  website, setWebsite,
  socialMedia, setSocialMedia,
  primaryContactName, setPrimaryContactName,
  primaryContactPosition, setPrimaryContactPosition,
  secondaryContactName, setSecondaryContactName,
  secondaryContactPhone, setSecondaryContactPhone,
  industryType, setIndustryType,
  businessType, setBusinessType,
  preferredContactMethod, setPreferredContactMethod,
  notes, setNotes,
  paymentTerms, setPaymentTerms,
  creditLimit, setCreditLimit,
  isActive, setIsActive,
  tags, setTags, 
}) => {
  const [image, setImage] = useState(null);

//Account panels please update me
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('logo', file);
      try {
        const response = await axios.post(`https://paintquotepro-server.azurewebsites.net/api/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setLogo(response.data.filePath); 
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  /////please update me
  const handleSubmit = async (event) => {
    event.preventDefault();
     if (!companyName) {
       alert('Company Name is required');
       return;
     }
    const accountData = {
      companyName,
      companyNumber,
      vatNumber,
      streetAddress,
      city,
      state,
      postalCode,
      country,
      email,
      phoneNumber,
      whatsApp,
      website,
      socialMedia,
      primaryContactName,
      primaryContactPosition,
      secondaryContactName,
      secondaryContactPhone,
      industryType,
      businessType,
      preferredContactMethod,
      notes,
      paymentTerms,
      creditLimit,
      isActive,
      tags,
    };

    try {
      const response = await axios.post(`https://paintquotepro-server.azurewebsites.net/api/accounts`, accountData);
      console.log('Account saved:', response.data);
      // You might want to show a success message to the user here
    } catch (error) {
      console.error('Error saving account:', error);
      // You might want to show an error message to the user here
    }
  };

  const inputStyle = {
    margin: '15px 0',
    '& .MuiInputBase-root': {
      height: '40px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.1rem',
      color: 'black',
    },
    '& .MuiInputBase-input': {
      fontSize: '1.1rem',
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  };

  return (
    <Box p={2} border="0px solid #fff" borderRadius="8px" style={{
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)', // For Safari support
    }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={1} exclusive  fullWidth>
          <CustomToggleButton value="paint"><FormatPaint /></CustomToggleButton>
          <CustomToggleButton value="colour"><ColorLens /></CustomToggleButton>
          <CustomToggleButton value="slant"><ViewQuilt /></CustomToggleButton>
          <CustomToggleButton value="mosaic"><Gradient /></CustomToggleButton>
          <CustomToggleButton value="gradient"><Gradient /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      {logo ? (
        <Box my={2}>
          <Box mt={2}>
            <img src={`https://paintquotepro-server.azurewebsites.net/${logo}`} alt="Uploaded Logo" style={{ width: '50%', borderRadius: '8px' }} />
          </Box>
        </Box>
      ) : (
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            Upload Image
          </Typography>
          <Button
            variant="contained"
            component="label"
          >
            Upload File
            <input
              type="file"
              hidden
              onChange={handleImageUpload}
            />
          </Button>
          {image && (
            <Box mt={2}>
              <img src={image} alt="Uploaded" style={{ width: '100%', borderRadius: '8px' }} />
            </Box>
          )}
        </Box>
      )}
      
      <Box my={2}>
        <Typography variant="h6" gutterBottom>
          Current Details
        </Typography>
        <TextField
          label="Company Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Business />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Company Number"
          value={companyNumber}
          onChange={(e) => setCompanyNumber(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ConfirmationNumber />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="VAT Number"
          value={vatNumber}
          onChange={(e) => setVatNumber(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Gavel />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Street Address"
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Home />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationCity />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="State/Province"
          value={state}
          onChange={(e) => setState(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Postal/ZIP Code"
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Place />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          fullWidth
          sx={inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Public />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2 }}
        >
          Save Account Details
        </Button>
      </Box>
    </Box>
  );
};

export default AccountPanel;
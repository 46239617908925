import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './Mapping.css';

const MapPanel = () => {
  const mapRef = useRef(null);
  const [steps, setSteps] = useState([]);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchDirections = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}api/directions?start=OX25%205QH&end=OX2%209RH`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.routes || data.routes.length === 0) {
          console.error('No routes found:', data);
          return;
        }

        if (!window.google) {
          console.error('Google Maps JavaScript API not loaded');
          return;
        }

        const map = new window.google.maps.Map(mapRef.current, {
          zoom: 7,
          center: { lat: 51.75, lng: -1.25 },
        });

        const trafficLayer = new window.google.maps.TrafficLayer();
        trafficLayer.setMap(map);

        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);

        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
          {
            origin: 'OX25 5QH',
            destination: 'OX2 9HR',
            travelMode: window.google.maps.TravelMode.DRIVING,
            drivingOptions: {
              departureTime: new Date(),
              trafficModel: 'pessimistic',
            },
          },
          (result, status) => {
            if (status === 'OK') {
              directionsRenderer.setDirections(result);
              const steps = result.routes[0].legs[0].steps.map(step => step.instructions);
              setSteps(steps);
            } else {
              console.error('Directions request failed due to ', status);
            }
          }
        );
      } catch (error) {
        console.error('Failed to fetch directions:', error);
      }
    };

    fetchDirections();
  }, []);

  return (
    <Box display="flex" justifyContent="center" width="95%">
      <Box p={2} border="0px solid #fff" borderRadius="8px" boxShadow={0} width="95%" style={{
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)', // For Safari support
      }}>
        <Box className="postcode-container">
          <Box className="postcode-item">
            <LocationOnIcon className="location-icon" />
            <Typography className="postcode-text">OX25 5QH</Typography>
          </Box>
          <Box className="postcode-item">
            <LocationOnIcon className="location-icon" />
            <Typography className="postcode-text">OX2 9RH</Typography>
          </Box>
        </Box>
        <div ref={mapRef} style={{ height: '500px', width: '100%' }}></div>
      </Box>
    </Box>
  );
};

export default MapPanel;

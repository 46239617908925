import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './CheckoutForm.css'; // Import the CSS file

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
      setError(error.message);
      return;
    }

    try {
      const response = await axios.post(`${API_ENDPOINT}api/stripe/create-payment-intent`, {
        amount: 1000, // Example amount in the smallest unit of the currency (e.g., cents for USD)
        currency: 'gbp',
      });

      const { clientSecret } = response.data;

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (result.error) {
        console.error('Payment failed:', result.error);
        setError(result.error.message);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          setSuccess(true);
          console.log('Payment succeeded:', result.paymentIntent);
        }
      }
    } catch (error) {
      console.error('Error during payment:', error);
      setError('Payment failed. Please try again.');
    }
  };

  // Custom styles for CardElement
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="CheckoutForm">
      <div style={{ marginBottom: '20px', padding: '12px', border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#fff' }}>
        <CardElement options={cardElementOptions} />
      </div>
      <button type="submit" disabled={!stripe} className="CheckoutButton">
        Pay
      </button>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">Payment successful!</div>}
    </form>
  );
};

export default CheckoutForm;
import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_ENDPOINT = 'https://paintquotepro-server.azurewebsites.net/';

const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${API_ENDPOINT}api/auth/register`, data);
      localStorage.setItem('token', res.data.token);
      navigate('/dashboard');
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register('name', { required: true })} placeholder="Name" />
      {errors.name && <span>Name is required</span>}
      
      <input {...register('email', { required: true })} placeholder="Email" />
      {errors.email && <span>Email is required</span>}
      
      <input {...register('password', { required: true, minLength: 6 })} placeholder="Password" type="password" />
      {errors.password && <span>Password is required</span>}
      
      <button type="submit">Register</button>
    </form>
  );
};

export default Register;
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const QuoteTable = ({ quotes, totals }) => {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" style={{ margin: '16px' }}>
        Quote Summary
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Room Name</TableCell>
            <TableCell align="right">Width (m)</TableCell>
            <TableCell align="right">Length (m)</TableCell>
            <TableCell align="right">Floor Area (m²)</TableCell>
            <TableCell align="right">Wall Area (m²)</TableCell>
            <TableCell align="right">Painting Hours</TableCell>
            <TableCell align="right">Painting Cost</TableCell>
            <TableCell align="right">Paint Required (L)</TableCell>
            <TableCell align="right">Paint Cost</TableCell>
            <TableCell align="right">Prep Cost</TableCell>
            <TableCell align="right">Woodwork Cost</TableCell>
            <TableCell align="right">Total Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotes.map((quote) => (
            <TableRow key={quote.id}>
              <TableCell component="th" scope="row">{quote.name}</TableCell>
              <TableCell align="right">{quote.widthInMeters}</TableCell>
              <TableCell align="right">{quote.lengthInMeters}</TableCell>
              <TableCell align="right">{quote.floorArea}</TableCell>
              <TableCell align="right">{quote.wallArea}</TableCell>
              <TableCell align="right">{quote.paintingHours}</TableCell>
              <TableCell align="right">{quote.paintingCost}</TableCell>
              <TableCell align="right">{quote.paintRequired}</TableCell>
              <TableCell align="right">{quote.paintCost}</TableCell>
              <TableCell align="right">{quote.prepCost}</TableCell>
              <TableCell align="right">{quote.skirtingArchitraveCost}</TableCell>
              <TableCell align="right">{quote.totalCost}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" scope="row"><strong>Total</strong></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{totals.totalFloorArea}</TableCell>
            <TableCell align="right">{totals.totalWallArea}</TableCell>
            <TableCell align="right">{totals.totalPaintingHours}</TableCell>
            <TableCell align="right">{totals.totalPaintingCost}</TableCell>
            <TableCell align="right">{totals.totalPaintRequired}</TableCell>
            <TableCell align="right">{totals.totalPaintCost}</TableCell>
            <TableCell align="right">{totals.totalPrepCost}</TableCell>
            <TableCell align="right">{totals.totalSkirtingArchitraveCost}</TableCell>
            <TableCell align="right">{totals.totalCost}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuoteTable;

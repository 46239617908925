import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setToken } from './utils/auth'; // Import the setToken utility

// const API_ENDPOINT = 'https://paintquotepro-server.azurewebsites.net/';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`https://paintquotepro-server.azurewebsites.net/api/auth/login`, data);
      console.log('Token:', res.data.token);
      setToken(res.data.token); 
      console.log('Navigating to dashboard');
      navigate('/dashboard'); 
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register('email', { required: true })} placeholder="Email" />
      {errors.email && <span>Email is required</span>}
      
      <input {...register('password', { required: true })} placeholder="Password" type="password" />
      {errors.password && <span>Password is required</span>}
      
      <button type="submit">Login</button>
    </form>
  );
};

export default Login;

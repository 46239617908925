import React from 'react';
import { Rect, Text } from 'react-konva';
import { HANDLE_SIZE, PIXELS_PER_METER } from './Constants';

const Room = ({
  room,
  selectedRoomId,
  draggingRoomId,
  selectedWall,
  handleDragStart,
  handleDragMove,
  handleDragEnd,
  handleRoomClick,
  handleWallClick,
  handleMouseEnter,
  handleMouseLeave,
  handleResizeMouseEnter,
  handleResizeMouseLeave,
  handleResizeMouseDown,
}) => {
  const showHandles = selectedRoomId === room.id;

  return (
    <React.Fragment>
      <Rect
        name="room"
        id={room.id}
        x={room.x}
        y={room.y}
        width={room.width}
        height={room.length}
        fill={selectedRoomId === room.id || draggingRoomId === room.id ? 'lightblue' : '#eee'}
        stroke={draggingRoomId === room.id ? 'black' : 'black'}
        strokeWidth={20}
        draggable
        onDragStart={() => handleDragStart(room.id)}
        onDragMove={(e) => handleDragMove(room, e)}
        onDragEnd={(e) => handleDragEnd(room, e)}
        onClick={() => handleRoomClick(room.id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <Text
        name="text"
        x={room.x + room.width / 2}
        y={room.y + room.length / 2 - 10}
        text={room.name}
        fontSize={16}
        fill="black"
        align="center"
        verticalAlign="middle"
        width={Math.abs(room.width)}
        offsetX={Math.abs(room.width) / 2}
        onClick={(e) => (e.cancelBubble = true)}
      />
      {Math.abs(room.width) >= PIXELS_PER_METER * 2 && Math.abs(room.length) >= PIXELS_PER_METER * 2 && (
        <Text
          name="text"
          x={room.x + room.width / 2}
          y={room.y + room.length / 2 + 10}
          text={`${Math.abs(room.width / PIXELS_PER_METER).toFixed(2)}m x ${Math.abs(room.length / PIXELS_PER_METER).toFixed(2)}m`}
          fontSize={12}
          fill="black"
          align="center"
          verticalAlign="middle"
          width={Math.abs(room.width)}
          offsetX={Math.abs(room.width) / 2}
          onClick={(e) => (e.cancelBubble = true)}
        />
      )}
      {['top', 'bottom', 'left', 'right'].map((wall) => (
        <Rect
          key={wall}
          name="wall"
          x={wall === 'left' || wall === 'right' ? (wall === 'left' ? room.x : room.x + room.width - HANDLE_SIZE * 2.5) : room.x}
          y={wall === 'top' || wall === 'bottom' ? (wall === 'top' ? room.y : room.y + room.length - HANDLE_SIZE * 2.5) : room.y}
          width={wall === 'left' || wall === 'right' ? HANDLE_SIZE * 2.5 : room.width}
          height={wall === 'top' || wall === 'bottom' ? HANDLE_SIZE * 2.5 : room.length}
          fill={room.walls[wall]?.color || 'white'}
          stroke={selectedWall.roomId === room.id && selectedWall.wall === wall ? 'aqua' : 'white'}
          strokeWidth={selectedWall.roomId === room.id && selectedWall.wall === wall ? 4 : 1}
          onClick={() => handleWallClick(room.id, wall)}
          onMouseEnter={(e) => {
            e.target.getStage().container().style.cursor = 'pointer';
          }}
          onMouseLeave={(e) => {
            e.target.getStage().container().style.cursor = 'default';
          }}
        />
      ))}
      {showHandles && ['top-left', 'top-right', 'bottom-left', 'bottom-right'].map((handle) => (
        <Rect
          key={handle}
          name="handle"
          x={handle.includes('left') ? room.x - HANDLE_SIZE / 2 : room.x + room.width - HANDLE_SIZE / 2}
          y={handle.includes('top') ? room.y - HANDLE_SIZE / 2 : room.y + room.length - HANDLE_SIZE / 2}
          width={HANDLE_SIZE}
          height={HANDLE_SIZE}
          stroke="darkblue"
          strokeWidth={2}
          fill="white"
          onMouseEnter={(e) => handleResizeMouseEnter(e, room.id, handle)}
          onMouseLeave={handleResizeMouseLeave}
          onMouseDown={() => handleResizeMouseDown(room.id, handle)}
          visible
        />
      ))}
    </React.Fragment>
  );
};

export default Room;
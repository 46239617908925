import React from 'react';
import { Box, Typography, Tooltip, tooltipClasses } from '@mui/material';
import { GridView, Gradient, ViewStream, ViewQuilt } from '@mui/icons-material';
import { styled } from '@mui/system';
import CustomSlider from './CustomSlider';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '18px',
    padding: '8px',
  },
});

const PaintOptions = ({
  style,
  setStyle,
  coverage,
  setCoverage,
  metersSquared,
  setMetersSquared,
  roomHeight,
  setRoomHeight,
  wallCoats,
  setWallCoats,
  roomPrep,
  setRoomPrep,
  color,
  setColor,
}) => {
  const handleChange = (setter) => (event, value) => {
    setter(value);
  };

  return (
    <Box
      p={2}
      borderRadius="8px"
      boxShadow={0}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        backdropFilter: 'blur(3px)',
        WebkitBackdropFilter: 'blur(3px)',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Job Type
      </Typography>
      <Box my={2}>
        <CustomToggleButtonGroup
          value={style}
          exclusive
          onChange={handleChange(setStyle)}
          fullWidth
        >
          <CustomTooltip title="Walls Only">
            <CustomToggleButton value="pattern">
              <ViewStream />
            </CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Ceiling">
            <CustomToggleButton value="grid">
              <GridView />
            </CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Skirting/Archetrave">
            <CustomToggleButton value="slant">
              <ViewQuilt />
            </CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Doors">
            <CustomToggleButton value="mosaic">
              <Gradient />
            </CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Windows">
            <CustomToggleButton value="gradient">
              <Gradient />
            </CustomToggleButton>
          </CustomTooltip>
        </CustomToggleButtonGroup>
      </Box>
      <Typography variant="subtitle1" gutterBottom>
        Paint Coverage
      </Typography>
      <CustomSlider
        value={coverage}
        onChange={handleChange(setCoverage)}
        valueLabelDisplay="auto"
        min={10}
        max={20}
      />
      <Typography variant="subtitle1" gutterBottom>
        Productivity (m²/hour)
      </Typography>
      <CustomSlider
        value={metersSquared}
        onChange={handleChange(setMetersSquared)}
        valueLabelDisplay="auto"
        min={10}
        max={20}
      />
      <Typography variant="subtitle1" gutterBottom>
        Room Height
      </Typography>
      <CustomSlider
        value={roomHeight}
        onChange={handleChange(setRoomHeight)}
        valueLabelDisplay="auto"
        min={2.5}
        max={7.0}
        step={0.1}
      />
      <Box my={2}>
        <Typography variant="subtitle1" gutterBottom>
          Wall Coats
        </Typography>
        <CustomToggleButtonGroup
          value={wallCoats}
          exclusive
          onChange={handleChange(setWallCoats)}
          fullWidth
        >
          <CustomTooltip title="One">
            <CustomToggleButton value="1">One</CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="Two">
            <CustomToggleButton value="2">Two</CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="Three">
            <CustomToggleButton value="3">Three</CustomToggleButton>
          </CustomTooltip>
        </CustomToggleButtonGroup>
      </Box>
      <Box my={2}>
        <Typography variant="subtitle1" gutterBottom>
          Room Prep
        </Typography>
        <CustomToggleButtonGroup
          value={roomPrep}
          exclusive
          onChange={handleChange(setRoomPrep)}
          fullWidth
        >
          <CustomTooltip title="Low">
            <CustomToggleButton value="low">Low</CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="Medium">
            <CustomToggleButton value="medium">Med</CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="High">
            <CustomToggleButton value="high">High</CustomToggleButton>
          </CustomTooltip>
        </CustomToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default PaintOptions;
import React from 'react';
import { Box } from '@mui/material';
import CustomTooltip from './components/CustomTooltip'; // Adjust the import path as necessary
import {
  Home,
  HomeOutlined,
  Person,
  PersonOutlined,
  People,
  PeopleOutlined,
  CalendarToday,
  CalendarTodayOutlined,
  Apps,
  AppsOutlined,
  FormatPaint,
  FormatPaintOutlined,
  ColorLens,
  ColorLensOutlined,
  FormatShapes,
  FormatShapesOutlined,
  MonetizationOn,
  MonetizationOnOutlined,
  Map,
  MapOutlined,
  ContentPasteSearch,
  ContentPasteSearchOutlined,
} from '@mui/icons-material';

const IconColumn = ({ activePanel, handleIconClick }) => {
  const commonStyles = {
    borderRadius: '4px',
    padding: '0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const iconDict = {
    accountPanel: { active: <Home fontSize="large" />, inactive: <HomeOutlined fontSize="large" /> },
    clientPanel: { active: <Person fontSize="large" />, inactive: <PersonOutlined fontSize="large" /> },
    personPanel: { active: <People fontSize="large" />, inactive: <PeopleOutlined fontSize="large" /> },
    inspectionPanel: { active: <ContentPasteSearch fontSize="large" />, inactive: <ContentPasteSearchOutlined fontSize="large" /> },
    schedulePanel: { active: <CalendarToday fontSize="large" />, inactive: <CalendarTodayOutlined fontSize="large" /> },
    floorPlanOptions: { active: <Apps fontSize="large" />, inactive: <AppsOutlined fontSize="large" /> },
    paintOptions: { active: <FormatPaint fontSize="large" />, inactive: <FormatPaintOutlined fontSize="large" /> },
    colourOptions: { active: <ColorLens fontSize="large" />, inactive: <ColorLensOutlined fontSize="large" /> },
    roomOptions: { active: <FormatShapes fontSize="large" />, inactive: <FormatShapesOutlined fontSize="large" /> },
    invoicePanel: { active: <MonetizationOn fontSize="large" />, inactive: <MonetizationOnOutlined fontSize="large" /> },
    mapPanel: { active: <Map fontSize="large" />, inactive: <MapOutlined fontSize="large" /> },
  };

  const icons = [
    { title: 'Account Details', panel: 'accountPanel', index: 0 },
    { title: 'Contractors', panel: 'personPanel', index: 2 },
    { title: 'Client Details', panel: 'clientPanel', index: 1 },
    { title: 'Inspection', panel: 'inspectionPanel', index: 0 },
    { title: 'Job Options', panel: 'floorPlanOptions', index: 0 },
    { title: 'Paint Options', panel: 'paintOptions', index: 0 },
    { title: 'Colour Options', panel: 'colourOptions', index: 0 },
    { title: 'Room Options', panel: 'roomOptions', index: 0 },
    { title: 'Schedule', panel: 'schedulePanel', index: 3 },
    { title: 'Invoice Options', panel: 'invoicePanel', index: 6 },
    { title: 'Directions', panel: 'mapPanel', index: 7 },
  ];

  return (
    <div className="column icon-column">
      <Box
        p={2}
        border="0px solid #fff"
        borderRadius="0px"
        boxShadow={0}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.0)',
        }}
      >
        {icons.map((icon) => (
          <CustomTooltip title={icon.title} placement="right" key={icon.panel}>
            <Box
              className="icon"
              onClick={() => handleIconClick(icon.panel, icon.index)}
              sx={{
                ...commonStyles,
                backgroundColor: activePanel === icon.panel ? '#B0C4DE' : 'transparent',
              }}
            >
              {activePanel === icon.panel ? iconDict[icon.panel].active : iconDict[icon.panel].inactive}
            </Box>
          </CustomTooltip>
        ))}
      </Box>
    </div>
  );
};

export default IconColumn;
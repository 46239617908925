const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const generateSchedule = async (quoteData) => {
  const response = await fetch(`${API_ENDPOINT}api/generate-schedule`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ quoteData }),
  });

  const data = await response.json();
  return data.schedule;
};

export default generateSchedule;

import React, { useState, useEffect } from 'react';
import { Gradient, ViewQuilt, ColorLens, FormatPaint, AlignVerticalTop, CheckBoxOutlineBlank, AlignHorizontalLeft, AlignHorizontalRight, AlignVerticalBottom } from '@mui/icons-material';
import { Box, Typography, Tooltip, tooltipClasses, TextField } from '@mui/material';
import { styled } from '@mui/system';

import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import { PIXELS_PER_METER } from './components/Floorplan/Constants';

const inputStyles = { fontSize: '14px', padding: '2px' };

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '18px',
    padding: '8px',
  },
}));

const SwatchBox = styled(Box)(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '3px',
  borderRadius: '3px',
  backgroundColor: '#fff',
  margin: '3px 3px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s, box-shadow 0.2s, border 0.2s',
  transform: selected ? 'scale(1)' : 'none',
  border: selected ? '2px solid black' : 'none',
}));

const SwatchColorBox = styled(Box)({
  width: '70px',
  height: '70px',
  borderRadius: '3px',
  padding: '3px',
  marginBottom: '5px',
  position: 'relative',
});

const SwatchName = styled(Typography)({
  fontSize: '12px',
  textAlign: 'center',
});

const RoomOptions = ({
  roomName, setRoomName, roomLength, setRoomLength, roomWidth, setRoomWidth,
  paintBrandRoom, setPaintBrandRoom, paintHueRoom, setPaintHueRoom,
  paintColor, setPaintColor, skirtingBoards, setSkirtingBoards,
  architrave, setArchitrave, windows, setWindows, doors, setDoors,
  ceiling, setCeiling, paintFinish, setPaintFinish, style, handleStyleChange,
  rooms, setRooms, selectedRoomId, setSelectedColor
}) => {
  const [selectedOption, setSelectedOption] = useState('paint');
  const [selectedSwatch, setSelectedSwatch] = useState(null);

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleChange = (name) => (e) => {
    const value = e.target.value;

    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === selectedRoomId
          ? {
              ...room,
              [name]: name === 'name' 
                ? value 
                : parseFloat(value) * PIXELS_PER_METER,
            }
          : room
      )
    );
  };

  const handleSwatchClick = (color, wall) => {
    setSelectedColor(color);
    setSelectedSwatch(wall);
    
    // Update the room's wall color
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === selectedRoomId
          ? {
              ...room,
              walls: {
                ...room.walls,
                [wall]: {
                  ...room.walls[wall],
                  color: color,
                },
              },
            }
          : room
      )
    );
  };

  const renderOptionInputs = () => {
    switch (selectedOption) {
      case 'paint':
        return (
          <Box>
            <TextField
              fullWidth
              label="Paint Brand"
              value={paintBrandRoom}
              onChange={(e) => setPaintBrandRoom(e.target.value)}
              margin="normal"
              InputProps={{ style: inputStyles }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
            <TextField
              fullWidth
              label="Paint Type"
              value={paintHueRoom}
              onChange={(e) => setPaintHueRoom(e.target.value)}
              margin="normal"
              InputProps={{ style: inputStyles }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
            <TextField
              fullWidth
              label="Paint Size"
              value={paintColor}
              onChange={(e) => setPaintColor(e.target.value)}
              margin="normal"
              InputProps={{ style: inputStyles }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
          </Box>
        );
      case 'colour':
        const selectedRoom = rooms.find(room => room.id === selectedRoomId);
        if (!selectedRoom) return null;

        const wallColors = [
          { name: 'Left Wall', color: selectedRoom.walls.left.color || '#FFFFFF', wall: 'left' },
          { name: 'Right Wall', color: selectedRoom.walls.right.color || '#FFFFFF', wall: 'right' },
          { name: 'Top Wall', color: selectedRoom.walls.top.color || '#FFFFFF', wall: 'top' },
          { name: 'Bottom Wall', color: selectedRoom.walls.bottom.color || '#FFFFFF', wall: 'bottom' },
        ];

        return (
          <Box>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2} mt={2}>
              {wallColors.map((wallColor, index) => (
                <SwatchBox 
                  key={index} 
                  onClick={() => handleSwatchClick(wallColor.color, wallColor.wall)} 
                  selected={selectedSwatch === wallColor.wall}
                >
                  <SwatchColorBox bgcolor={wallColor.color} />
                  <SwatchName>{wallColor.name}</SwatchName>
                </SwatchBox>
              ))}
            </Box>
          </Box>
        );
        case 'slant':
          return (
            <Box my={2}>
              <Typography variant="subtitle1" gutterBottom>Number of windows</Typography>
              <CustomToggleButtonGroup value={windows} exclusive onChange={(e, value) => setWindows(value)} fullWidth>
                <CustomTooltip title="One">
                  <CustomToggleButton value={1}>One</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Two">
                  <CustomToggleButton value={2}>Two</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Three">
                  <CustomToggleButton value={3}>Three</CustomToggleButton>
                </CustomTooltip>
              </CustomToggleButtonGroup>
              
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Number of doors</Typography>
              <CustomToggleButtonGroup value={1} exclusive onChange={(e, value) => setDoors(value)} fullWidth>
                <CustomTooltip title="Small">
                  <CustomToggleButton value="1">One</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Medium">
                  <CustomToggleButton value="2">Two</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Large">
                  <CustomToggleButton value="3">Three</CustomToggleButton>
                </CustomTooltip>
              </CustomToggleButtonGroup>
            </Box>
          );
      case 'mosaic':
        return (
          <Box>
            <Typography>Mosaic options will be here</Typography>
          </Box>
        );
      case 'gradient':
        return (
          <Box>
            <Typography>Gradient options will be here</Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box p={0} borderRadius="0px" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.6)', backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)' }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <CustomToggleButton value="paint"><FormatPaint /></CustomToggleButton>
          <CustomToggleButton value="colour"><ColorLens /></CustomToggleButton>
          <CustomToggleButton value="slant"><ViewQuilt /></CustomToggleButton>
          <CustomToggleButton value="mosaic"><Gradient /></CustomToggleButton>
          <CustomToggleButton value="gradient"><Gradient /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: '15px' }}>
        <TextField
          fullWidth
          label="Room Name"
          value={roomName}
          onChange={handleChange('name')}
          margin="normal"
          InputProps={{ style: inputStyles }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
        />

        <Typography gutterBottom style={{ fontSize: '12px' }}>Dimensions (meters)</Typography>
        <Box display="flex" justifyContent="space-between">
          <TextField
            label="Width"
            type="number"
            value={roomWidth}
            onChange={handleChange('width')}
            margin="normal"
            style={{ width: '45%' }}
            InputProps={{ style: inputStyles }}
            InputLabelProps={{ style: { fontSize: '14px' } }}
          />
          <TextField
            label="Length"
            type="number"
            value={roomLength}
            onChange={handleChange('length')}
            margin="normal"
            style={{ width: '45%' }}
            InputProps={{ style: inputStyles }}
            InputLabelProps={{ style: { fontSize: '14px' } }}
          />
        </Box>

        <Box my={2}>
          <CustomToggleButtonGroup value={style} exclusive onChange={handleStyleChange} fullWidth>
            <CustomTooltip title="All Walls">
              <CustomToggleButton value="allWalls"><CheckBoxOutlineBlank /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Left Wall">
              <CustomToggleButton value="left"><AlignHorizontalLeft /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Top Wall">
              <CustomToggleButton value="top"><AlignVerticalTop /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Bottom Wall">
              <CustomToggleButton value="bottom"><AlignVerticalBottom /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Right Wall">
              <CustomToggleButton value="right"><AlignHorizontalRight /></CustomToggleButton>
            </CustomTooltip>
          </CustomToggleButtonGroup>
        </Box>
        {renderOptionInputs()}
      </Box>
    </Box>
  );
};

export default RoomOptions;
import React, { useState, useEffect } from 'react';
import { Box, Typography, Tooltip, tooltipClasses, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '18px',
    padding: '8px',
  },
});

const hueColors = {
  'Blue': '#0000FF',
  'Cool Neutral': '#A9A9A9',
  'Gold': '#FFD700',
  'Green': '#008000',
  'Lime': '#00FF00',
  'Orange': '#FFA500',
  'Purple': '#800080',
  'Red': '#FF0000',
  'Teal': '#008080',
  'Warm Neutral': '#D2B48C',
  'White': '#FFFFFF',
  'Yellow': '#FFFF00',
  'Grey': '#333333',
  'Pink': '#FFC0CB',
  'Cream': '#FF00FF',
  'Metallic': '#000000',
  'Neutral': '#225566',
};

const getGrayscaleValue = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return 0.299 * r + 0.587 * g + 0.114 * b;
};

const SwatchBox = styled(Box)(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '3px',
  borderRadius: '3px',
  backgroundColor: '#fff',
  margin: '3px 3px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s, box-shadow 0.2s, border 0.2s',
  transform: selected ? 'scale(0.9)' : 'none',
  border: selected ? '2px solid black' : 'none',
  '&:hover': {
    transform: 'scale(1.1)',
    border: '1px solid black',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
}));

const SwatchColorBox = styled(Box)({
  width: '70px',
  height: '70px',
  borderRadius: '3px',
  padding: '3px',
  marginBottom: '15px',
  position: 'relative',
});

const SwatchHex = styled(Typography)({
  position: 'absolute',
  bottom: '5px',
  left: '10px',
  color: '#333',
  fontSize: '14px',
});

const SwatchName = styled(Typography)({
  marginTop: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#222',
  textAlign: 'center',
});

const ColourOptions = ({
  style,
  setStyle,
  coverage,
  setCoverage,
  metersSquared,
  setMetersSquared,
  roomHeight,
  setRoomHeight,
  wallCoats,
  setWallCoats,
  roomPrep,
  setRoomPrep,
  color,
  setColor,
  paintBrand,
  setPaintBrand,
  paintHue,
  setPaintHue,
  rooms,
  selectedRoomId,
  setSelectedRoomId,
  setSelectedColor, // Add this line to receive the setter from App.js
}) => {
  const [paintBrands, setPaintBrands] = useState([]);
  const [paintHues, setPaintHues] = useState([]);
  const [availableHues, setAvailableHues] = useState([]);
  const [savedPalettes, setSavedPalettes] = useState([]);
  const [selectedPalette, setSelectedPalette] = useState('');
  const [swatchData, setSwatchData] = useState([]);
  const [selectedSwatch, setSelectedSwatch] = useState(null); // Track the selected swatch

  const fetchSavedPalettes = () => {
    const savedPalettesData = JSON.parse(localStorage.getItem('savedPalettes') || '[]');
    setSavedPalettes(savedPalettesData);
    
    // If there are palettes and no palette is currently selected, select the first one
    if (savedPalettesData.length > 0 && !selectedPalette) {
      const firstPalette = savedPalettesData[0];
      setSelectedPalette(firstPalette.name);
      setSwatchData(firstPalette.swatchData);
    }
  };

  useEffect(() => {
    if (savedPalettes.length > 0 && !selectedPalette) {
      const firstPalette = savedPalettes[0];
      setSelectedPalette(firstPalette.name);
      setSwatchData(firstPalette.swatchData);
    }
  }, [savedPalettes]);
  
  useEffect(() => {
  fetch('/json/paintBrands.json')
    .then(response => response.json())
    .then(data => {
      setPaintBrands(data.paintBrands);
    });

  fetch('/json/paintHues.json')
    .then(response => response.json())
    .then(data => {
      setPaintHues(data.paintHues);
    });

  fetchSavedPalettes();

  // Add event listener to handle storage change
  window.addEventListener('storage', fetchSavedPalettes);

  // Cleanup event listener
  return () => {
    window.removeEventListener('storage', fetchSavedPalettes);
  };
}, []);

  useEffect(() => {
    if (paintBrand) {
      const selectedBrand = paintBrands.find(brand => brand.name === paintBrand);
      if (selectedBrand) {
        const hues = paintHues.filter(hue => selectedBrand.hueIDs.includes(hue.hueID));
        hues.sort((a, b) => getGrayscaleValue(hueColors[a.name]) - getGrayscaleValue(hueColors[b.name]));
        setAvailableHues(hues);
      } else {
        setAvailableHues([]);
      }
    } else {
      setAvailableHues([]);
    }
  }, [paintBrand, paintBrands, paintHues]);

  const handlePaintBrandChange = (event) => {
    const selectedBrandName = event.target.value;
    setPaintBrand(selectedBrandName);
    setPaintHue(''); // Reset hue when brand changes
  };

  const handlePaintHueChange = (event) => {
    setPaintHue(event.target.value);
  };

  const handlePaletteChange = (event) => {
    const paletteName = event.target.value;
    setSelectedPalette(paletteName);

    // Apply the selected palette
    const palette = savedPalettes.find(p => p.name === paletteName);
    if (palette) {
      setSwatchData(palette.swatchData);
    }
  };

  const handleSwatchClick = (color) => {
    setSelectedColor(color);
    setSelectedSwatch(color); // Set the selected swatch
  };

  return (
    <Box p={2} border="0px solid white" borderRadius="8px" boxShadow={0} style={{
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)', // For Safari support
    }}>
      <Typography sx={{ fontSize: '22px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>
        Palette Options
      </Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel style={{ fontSize: '14px' }}>Paint Brand</InputLabel>
        <Select
          value={paintBrand || ''}
          onChange={handlePaintBrandChange}
          style={{ fontSize: '14px' }}
        >
          {paintBrands.map((brand) => (
            <MenuItem key={brand.brandID} value={brand.name}>{brand.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      {paintBrand && (
        <Box display="flex" flexWrap="wrap" mt={2}>
          {availableHues.map((hue) => (
            <Box
              key={hue.hueID}
              onClick={() => handleSwatchClick(hueColors[hue.name])} 
              width={50}
              height={50}
              bgcolor={hueColors[hue.name]}
              borderRadius="4px"
              m={1}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </Box>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel style={{ fontSize: '14px' }}>Select Palette</InputLabel>
        <Select
          value={selectedPalette}
          onChange={handlePaletteChange}
          style={{ fontSize: '14px' }}
        >
          {savedPalettes.map((palette, index) => (
            <MenuItem key={index} value={palette.name}>{palette.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
       <Box p={1} border="0px solid white" borderRadius="8px" style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }}>
        <Box display="flex" justifyContent="left" mb={2}>
          <img src="/images/logos/dulux.png" alt="Logo" style={{ maxHeight: '100px' }} />
        </Box>
        {swatchData.length > 0 && (
          <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={0} mt={2}>
            {swatchData.map((swatch, index) => (
              <SwatchBox key={index} onClick={() => handleSwatchClick(swatch.rgb)} selected={selectedSwatch === swatch.rgb}>
                <SwatchColorBox bgcolor={swatch.rgb}>
                  {/* <SwatchHex>{swatch.rgb}</SwatchHex> */}
                </SwatchColorBox>
                {/* <SwatchName>{swatch.name}</SwatchName> */}
              </SwatchBox>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ColourOptions;

// styles.js
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

export const SwatchBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderRadius: '3px',
  backgroundColor: '#fff',
  margin: '15px 5px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 1)',
});

export const SwatchColorBox = styled(Box)({
  width: '150px',
  height: '175px',
  borderRadius: '3px',
  border: '0px solid #333',
  marginBottom: '5px',
  position: 'relative',
});

export const SwatchHex = styled(Typography)({
  position: 'absolute',
  bottom: '5px',
  left: '10px',
  color: '#333',
  fontSize: '14px',
});

export const SwatchName = styled(Typography)({
  marginTop: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#222',
});

// App.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, LinearProgress, CircularProgress, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import FloorPlan from './components/Floorplan/Floorplan';
import AI from './AI';
import QuoteTable from './QuoteTable';
import Schedule from './Schedule';
import Contract from './Contract';
import Invoicing from './Invoicing';
import Mapping from './Mapping';
import Palette from './Palette';
import PaintOptions from './PaintOptions';
import ColourOptions from './ColourOptions';
import RoomOptions from './RoomOptions';
import FloorPlanOptions from './FloorPlanOptions';
import UploadFloorplan from './UploadFloorplan';
import AccountPanel from './AccountPanel';
import ClientPanel from './ClientPanel';
import SchedulePanel from './SchedulePanel';
import InvoicePanel from './InvoicePanel';
import MapPanel from './MapPanel';
import IconColumn from './IconColumn';
// import ThreeDScene from './ThreeDScene';

import { PIXELS_PER_METER } from './components/Floorplan/Constants'; // Import the constant
import './App.css';


const backgroundImages = {
  floorPlan: 'url(/images/quote_blue_0001.webp)',
  palette: 'url(/images/floorplan_blue.webp)',
  ai: 'url(/images/quote_blue_0001.webp)',
  quote: 'url(/images/quote_blue_0001.webp)',
  schedule: 'url(/images/scheduleImage.jpg)',
  contract: 'url(/images/contractImage.jpg)',
  invoicing: 'url(/images/invoicing_blue_001.webp)',
  mapping: 'url(/images/floorplan_blue.webp)'
};

const App = () => {

 

  const [paintBrand, setPaintBrand] = useState('');
  const [paintHue, setPaintHue] = useState('');
  const [paintBrandRoom, setPaintBrandRoom] = useState('');
  const [paintHueRoom, setPaintHueRoom] = useState('');
  const [activePanel, setActivePanel] = useState('accountPanel'); // State for active panel
  const [style, setStyle] = useState('pattern');
  const [coverage, setCoverage] = useState(15); // Paint coverage in m^2 per liter
  const [metersSquared, setMetersSquared] = useState(15); // Painter productivity in m^2 per hour
  const [roomHeight, setRoomHeight] = useState(2.5); // Room height in meters
  const [wallCoats, setWallCoats] = useState('1');
  const [roomPrep, setRoomPrep] = useState('low');
  const [color, setColor] = useState('gray');
  const [tabIndex, setTabIndex] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [newRoom, setNewRoom] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null); // State for selected room
  const [schedule, setSchedule] = useState(''); // State for storing generated schedule
  const [thumbnail, setThumbnail] = useState(null); // State for storing the uploaded thumbnail
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const [isAnalyzing, setIsAnalyzing] = useState(false); // State for analysis status
  const [contract, setContract] = useState(''); // State for storing the generated contract
  const [companyName, setCompanyName] = useState('PaintQuote Pro');
  const [companyNumber, setCompanyNumber] = useState('123456789');
  const [vatNumber, setVatNumber] = useState('003154154');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [logo, setLogo] = useState('');

  // State for ClientOptions
  const [clientFullName, setClientFullName] = useState('Matthew Rogers');
  const [clientEmail, setClientEmail] = useState('360precision@gmail.com');
  const [clientPhone, setClientPhone] = useState('07565134228');
  const [clientStreetAddress, setClientStreetAddress] = useState('Holly Cottage');
  const [clientCity, setClientCity] = useState('Bicester');
  const [clientState, setClientState] = useState('Oxfordshire');
  const [clientPostalCode, setClientPostalCode] = useState('OX25 5QH');
  const [clientCountry, setClientCountry] = useState('UK');

  // State for Schedule Options
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // State for Directions Options
  const [startPostcode, setStartPostcode] = useState(null);
  const [endPostcode, setEndPostcode] = useState(null);

  const [roomName, setRoomName] = useState('');
  const [roomLength, setRoomLength] = useState('');
  const [roomWidth, setRoomWidth] = useState('');
  const [paintColor, setPaintColor] = useState('');
  const [skirtingBoards, setSkirtingBoards] = useState(false);
  const [architrave, setArchitrave] = useState(false);
  const [windows, setWindows] = useState(false);
  const [doors, setDoors] = useState(false);
  const [ceiling, setCeiling] = useState(false);
  const [paintFinish, setPaintFinish] = useState('');
  const [invoicesState, setInvoicesState] = useState([
    { id: 1, percentage: 50, paid: false },
    { id: 2, percentage: 50, paid: false },
  ]);
  const [numInvoices, setNumInvoices] = useState(invoicesState.length); // State for number of invoices
  const [backgroundImage, setBackgroundImage] = useState(backgroundImages.floorPlan); // State for background image

  const [colorChartPath, setColorChartPath] = useState(''); // State for color chart path


  const [selectedColor, setSelectedColor] = useState(''); // Add this line for selected color


  const handleSwatchClick = (color) => {
    setSelectedColor(color);
  };
  


  useEffect(() => {
    setNumInvoices(invoicesState.length);
  }, [invoicesState]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    const backgroundImageKey = [
      'floorPlan',
      'palette',
      'ai',
      'quote',
      'schedule',
      'contract',
      'invoicing',
      'mapping',
    ][newIndex] || 'floorPlan';
    setBackgroundImage(backgroundImages[backgroundImageKey]);
  };

  const handleStyleChange = (event, newStyle) => {
    if (newStyle !== null) {
      setStyle(newStyle);
    }
  };


  const calculateQuote = (room) => {
    const widthInMeters = parseFloat(Math.abs(room.width) / PIXELS_PER_METER) || 0;
    const lengthInMeters = parseFloat(Math.abs(room.length) / PIXELS_PER_METER) || 0;
    const heightInMeters = parseFloat(roomHeight) || 0;
    const wallArea = 2 * (widthInMeters + lengthInMeters) * heightInMeters || 0; // Total wall area
    const wallPerimeter = 2 * (widthInMeters + lengthInMeters) || 0; // Total wall perimeter
    const floorArea = widthInMeters * lengthInMeters || 0; // Floor area

    const costPerHour = 30; // Assume a fixed cost per hour for the painter
    const paintPricePerLiter = 15;
    const prepCostPerMeter = 3;
    const skirtingArchitraveCostPerMeter = 3;

    const paintingHours = wallArea / metersSquared || 0; // Calculate the hours required to paint the wall area
    const paintingCost = paintingHours * costPerHour || 0;
    const paintRequired = wallArea / coverage || 0;
    const paintCost = paintRequired * paintPricePerLiter || 0;
    const prepCost = wallPerimeter * prepCostPerMeter || 0;
    const skirtingArchitraveCost = wallPerimeter * skirtingArchitraveCostPerMeter || 0;

    const totalCost = paintingCost + paintCost + prepCost + skirtingArchitraveCost || 0;

    return {
      ...room,
      widthInMeters: widthInMeters.toFixed(2),
      lengthInMeters: lengthInMeters.toFixed(2),
      floorArea: floorArea.toFixed(2),
      wallArea: wallArea.toFixed(2),
      wallPerimeter: wallPerimeter.toFixed(2),
      paintingHours: paintingHours.toFixed(2),
      paintingCost: paintingCost.toFixed(2),
      paintRequired: paintRequired.toFixed(2),
      paintCost: paintCost.toFixed(2),
      prepCost: prepCost.toFixed(2),
      skirtingArchitraveCost: skirtingArchitraveCost.toFixed(2),
      totalCost: totalCost.toFixed(2),
    };
  };

  const quotes = rooms.map(calculateQuote);
  const totalFloorArea = quotes.reduce((sum, quote) => sum + parseFloat(quote.floorArea), 0).toFixed(2);
  const totalWallArea = quotes.reduce((sum, quote) => sum + parseFloat(quote.wallArea), 0).toFixed(2);
  const totalWallPerimeter = quotes.reduce((sum, quote) => sum + parseFloat(quote.wallPerimeter), 0).toFixed(2);
  const totalPaintingHours = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintingHours), 0).toFixed(2);
  const totalPaintingCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintingCost), 0).toFixed(2);
  const totalPaintRequired = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintRequired), 0).toFixed(2);
  const totalPaintCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.paintCost), 0).toFixed(2);
  const totalPrepCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.prepCost), 0).toFixed(2);
  const totalSkirtingArchitraveCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.skirtingArchitraveCost), 0).toFixed(2);
  const totalCost = quotes.reduce((sum, quote) => sum + parseFloat(quote.totalCost), 0).toFixed(2);

  useEffect(() => {
    if (selectedRoomId) {
      const room = rooms.find((r) => r.id === selectedRoomId);
      if (room) {
        setRoomName(room.name);
        setRoomLength(Math.abs(room.length / PIXELS_PER_METER).toFixed(2));
        setRoomWidth(Math.abs(room.width / PIXELS_PER_METER).toFixed(2));
        setRoomHeight(roomHeight);
        setPaintBrand(room.paintBrand || '');
        setPaintHue(room.paintHue || '');
        setPaintColor(room.paintColor || '');
        setSkirtingBoards(room.skirtingBoards || false);
        setArchitrave(room.architrave || false);
        setWindows(room.windows || false);
        setDoors(room.doors || false);
        setCeiling(room.ceiling || false);
        setPaintFinish(room.paintFinish || '');
      }
    }
  }, [selectedRoomId, rooms, roomHeight]);

  const handleRoomChange = (updatedRoom) => {
    setRooms((prevRooms) =>
      prevRooms.map((room) => (room.id === updatedRoom.id ? updatedRoom : room))
    );
  };
  
  const handleIconClick = (panel, tab) => {
    setActivePanel(panel);
    if (tab !== undefined) {
      setTabIndex(tab);
      handleTabChange(null, tab);
    }
  };

  return (
    <div className="container" style={{ backgroundImage: backgroundImage, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="background-container">
      <div className="column icon-column">
      <IconColumn activePanel={activePanel} handleIconClick={handleIconClick} />
      
    </div>
        <div className="column panel-column">
          {activePanel === 'accountPanel' && (
            <AccountPanel
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyNumber={companyNumber}
              setCompanyNumber={setCompanyNumber}
              vatNumber={vatNumber}
              setVatNumber={setVatNumber}
              streetAddress={streetAddress}
              setStreetAddress={setStreetAddress}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              country={country}
              setCountry={setCountry}
              logo={logo}
              setLogo={setLogo}
            />
          )}
          {activePanel === 'clientPanel' && (
            <ClientPanel
              clientFullName={clientFullName}
              setClientFullName={setClientFullName}
              email={clientEmail}
              setClientEmail={setClientEmail}
              clientPhone={clientPhone}
              setClientPhone={setClientPhone}
              clientStreetAddress={clientStreetAddress}
              setClientStreetAddress={setClientStreetAddress}
              clientCity={clientCity}
              setClientCity={setClientCity}
              clientState={clientState}
              setClientState={setClientState}
              clientPostalCode={clientPostalCode}
              setClientPostalCode={setClientPostalCode}
              clientCountry={clientCountry}
              setClientCountry={setClientCountry}
            />
          )}
          {activePanel === 'paintOptions' && (
            <PaintOptions
              style={style}
              setStyle={setStyle}
              coverage={coverage}
              setCoverage={setCoverage}
              metersSquared={metersSquared}
              setMetersSquared={setMetersSquared}
              roomHeight={roomHeight}
              setRoomHeight={setRoomHeight}
              wallCoats={wallCoats}
              setWallCoats={setWallCoats}
              roomPrep={roomPrep}
              setRoomPrep={setRoomPrep}
              color={color}
              setColor={setColor}
            />
          )}
          {activePanel === 'colourOptions' && (
            <ColourOptions
              style={style}
              setStyle={setStyle}
              coverage={coverage}
              setCoverage={setCoverage}
              metersSquared={metersSquared}
              setMetersSquared={setMetersSquared}
              roomHeight={roomHeight}
              setRoomHeight={setRoomHeight}
              wallCoats={wallCoats}
              setWallCoats={setWallCoats}
              roomPrep={roomPrep}
              setRoomPrep={setRoomPrep}
              color={color}
              setColor={setColor}
              rooms={rooms}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              paintBrand={paintBrand}
              setPaintBrand={setPaintBrand}
              paintHue={paintHue}
              setPaintHue={setPaintHue}
              setSelectedColor={setSelectedColor}
              setColorChartPath={setColorChartPath}  
            />
          )}
          {activePanel === 'floorPlanOptions' && (
            <FloorPlanOptions
              rooms={rooms}
              setRooms={setRooms}
              newRoom={newRoom}
              setNewRoom={setNewRoom}
              selectedRoomId={selectedRoomId}
              setSelectedRoomId={setSelectedRoomId}
              selectedColor={selectedColor}
            />
          )}
          {activePanel === 'roomOptions' && (
            <RoomOptions
              roomName={roomName}
              setRoomName={setRoomName}
              roomLength={roomLength}
              setRoomLength={setRoomLength}
              roomWidth={roomWidth}
              setRoomWidth={setRoomWidth}
              paintBrandRoom={paintBrandRoom}
              setPaintBrandRoom={setPaintBrandRoom}
              paintHueRoom={paintHueRoom}
              setPaintHueRoom={setPaintHueRoom}
              paintColor={paintColor}
              setPaintColor={setPaintColor}
              skirtingBoards={skirtingBoards}
              setSkirtingBoards={setSkirtingBoards}
              architrave={architrave}
              setArchitrave={setArchitrave}
              windows={windows}
              setWindows={setWindows}
              doors={doors}
              setDoors={setDoors}
              ceiling={ceiling}
              setCeiling={setCeiling}
              paintFinish={paintFinish}
              setPaintFinish={setPaintFinish}
              style={style}
              handleStyleChange={handleStyleChange}
              rooms={rooms}
              setRooms={setRooms}
              selectedRoomId={selectedRoomId}
            />
          )}

          {activePanel === 'schedulePanel' && (
            <SchedulePanel
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
          {activePanel === 'invoicePanel' && (
            <InvoicePanel
              totalCost={totalCost} 
              invoicesState={invoicesState}
              setInvoicesState={setInvoicesState}
              setNumInvoices={setNumInvoices} // Pass setNumInvoices to InvoicePanel
            />
          )}
          {activePanel === 'mapPanel' && (
            <MapPanel
              startPostcode={startPostcode}
              setStartPostcode={setStartPostcode}
              endPostcode={endPostcode}
              setEndPostcode={setEndPostcode}
            />
          )}
        </div>
      </div>
      <div className="column main-column">
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="floorplan and quote tabs">
          <Tab label="Floorplan" />
          {/* <Tab label="3D view" /> */}
          <Tab label="Palette" />
          <Tab label="Designer" />
          <Tab label="Quote" />
          <Tab label="Schedule" />
          <Tab label="Contract" />
          <Tab label="Invoicing" />
          <Tab label="Directions" />
          
        </Tabs>
        {tabIndex === 0 && (
  <Box display="flex" height="100%">
    <Box width="70%" p={1}>
      <FloorPlan
        rooms={quotes}
        setRooms={setRooms}
        newRoom={newRoom}
        setNewRoom={setNewRoom}
        selectedRoomId={selectedRoomId}
        setSelectedRoomId={setSelectedRoomId}
        selectedColor={selectedColor} // Pass selected color here
      />
    </Box>
    <Box width="25%" p={1}>
      <UploadFloorplan
        setThumbnail={setThumbnail}
        setRooms={setRooms}
        setUploadProgress={setUploadProgress}
        setIsAnalyzing={setIsAnalyzing}
        PIXELS_PER_METER={PIXELS_PER_METER}
      />
      {uploadProgress > 0 && (
        <Box my={2}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
      {isAnalyzing && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
          <Typography variant="body2" color="textSecondary" style={{ marginLeft: 10 }}>
            Analyzing Floorplan...
          </Typography>
        </Box>
      )}
      {thumbnail && (
        <Box mb={2}>
          <img src={thumbnail} alt="Floorplan Thumbnail" style={{ width: '100%', height: 'auto' }} />
        </Box>
      )}
    </Box>
  </Box>
)}
         {/* {tabIndex === 1 && (
          <ThreeDScene rooms={rooms || []} roomHeight={roomHeight} /> 
        )} */}
        {tabIndex === 1 && (
          
          <Palette colorChartPath={colorChartPath} onSwatchClick={handleSwatchClick} />
        )}
        {tabIndex === 2 && (
          <AI />
        )}
        {tabIndex === 3 && (
          <QuoteTable
            quotes={quotes}
            totals={{ totalFloorArea, totalWallArea, totalWallPerimeter, totalPaintingHours, totalPaintingCost, totalPaintRequired, totalPaintCost, totalPrepCost, totalSkirtingArchitraveCost, totalCost }}
          />
        )}
        {tabIndex === 4 && (
          <Schedule quoteData={quotes} schedule={schedule} setSchedule={setSchedule} />
        )}
        {tabIndex === 5 && (
          <Contract quoteData={quotes} schedule={schedule} contract={contract} setContract={setContract} />
        )}
        {tabIndex === 6 && (
          <Invoicing
            logo={logo}
            clientFullName={clientFullName}
            clientStreetAddress={clientStreetAddress}
            clientCity={clientCity}
            clientState={clientState}
            clientPostalCode={clientPostalCode}
            clientCountry={clientCountry}
            numInvoices={numInvoices} 
          />
        )}
        {tabIndex === 7 && (
          <Mapping />
        )}
       
      </div>
    </div>
   
  );
};

export default App;
